import { apiInstance } from '@/api-requests/api-instance';
import { IAuthStore } from '@/contexts/auth';
import { saveStorageTokens } from '@/contexts/auth/save-storage-tokens';
import postMessage from '@/utils/post-message';
import { datadogRum } from '@datadog/browser-rum';
import { AxiosError } from 'axios';
import jwt_decode, { JwtPayload } from 'jwt-decode';

export const isTokenExpired = (token?: string) => {
  if (!token) return true;

  const decoded: JwtPayload = jwt_decode(token);
  return !decoded || (decoded.exp ?? 0) * 1000 <= Date.now();
};

export const configureApiInterceptors = (authStore: IAuthStore) => {
  apiInstance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${authStore.accessToken}`;
    return config;
  });

  apiInstance.interceptors.response.use(null, async (error: AxiosError) => {
    if (error.config?.url?.includes('auth/token/refresh')) {
      // invalidToken
      authStore.setIsTokenInvalid(true);
      window.parent.postMessage('invalidToken', '*'); // for backwards compatibility
      postMessage('invalidToken');
      datadogRum.addError(new Error('refreshToken failed'));

      // Ensure that a failed request for a refresh token hits the catch block
      return Promise.reject(error);
    }

    if (
      error.response?.status === 401 &&
      error.config &&
      !authStore.isTokenInvalid
    ) {
      try {
        await refreshTokens(authStore);
        return apiInstance.request(error.config);
      } catch {
        return Promise.reject(error);
      }
    } else if (error.response?.status === 401) {
      return Promise.reject({
        ...error,
        message: `${error.message} - Please restart or refresh this application to try again.`,
      });
    }

    return Promise.reject(error);
  });
};

const refreshTokens = async ({
  refreshToken,
  tokenRefreshPromise,
  setTokens,
  setTokenRefreshPromise,
}: IAuthStore) => {
  if (tokenRefreshPromise) return tokenRefreshPromise;

  const tokenPromise = apiInstance
    .post('/v0/auth/token/refresh', {
      refresh_token: refreshToken,
    })
    .then(({ data: newTokens }) => {
      setTokens(newTokens.access_token, newTokens.refresh_token);
      saveStorageTokens(newTokens.access_token, newTokens.refresh_token);
      setTokenRefreshPromise(null);
      return newTokens;
    })
    .catch((error) => {
      datadogRum.addError(new Error('API request to refresh token failed.'));
      setTokenRefreshPromise(null);
      return error;
    });

  setTokenRefreshPromise(tokenPromise);

  return tokenPromise;
};

export default configureApiInterceptors;
