import { useRouter } from 'next/router';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  configureApiInterceptors,
  isTokenExpired,
} from './configure-api-interceptors';
import { saveStorageTokens } from './save-storage-tokens';

interface Props {
  children: ReactNode;
}

export interface IAuthStore {
  setTokens: (accessToken: string, refreshToken: string) => void;
  setTokenRefreshPromise: (promise: Promise<any | null> | null) => void;
  tokenRefreshPromise: Promise<any | null> | null;
  accessToken: string | null;
  refreshToken: string | null;
  isTokenInvalid: boolean;
  setIsTokenInvalid: (isTokenInvalid: boolean) => void;
}

export class AuthStore implements IAuthStore {
  accessToken: string | null = null;
  refreshToken: string | null = null;
  isTokenInvalid = false;
  tokenRefreshPromise: Promise<any | null> | null = null;

  setTokenRefreshPromise = (value: Promise<any | null> | null) => {
    this.tokenRefreshPromise = value;
  };

  setTokens = (accessToken: string, refreshToken: string) => {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  };

  setIsTokenInvalid = (isInvalid: boolean) => {
    this.isTokenInvalid = isInvalid;
  };
}

const AuthContext = createContext<IAuthStore>(new AuthStore());

export const useAuthContext = () => useContext<IAuthStore>(AuthContext);

export const AuthProvider = ({ children }: Props) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { isReady, query } = useRouter();
  const authStore = useAuthContext();

  useEffect(() => {
    if (!isInitialized && isReady) {
      const _accessToken = query?.access_token
        ? String(query.access_token)
        : window.localStorage.getItem('access_token');

      const _refreshToken = query?.refresh_token
        ? String(query.refresh_token)
        : window.localStorage.getItem('refresh_token');

      try {
        if (!_accessToken || !_refreshToken) {
          throw new Error('Missing tokens');
        }
        if (isTokenExpired(_refreshToken)) {
          throw new Error('Refresh token expired');
        }

        // save tokens for subsequent requests
        saveStorageTokens(_accessToken, _refreshToken);
        authStore.setTokens(_accessToken, _refreshToken);

        configureApiInterceptors(authStore);
        setIsInitialized(true);
      } catch {
        setIsInitialized(false);
      }
    }
  }, [
    authStore,
    isReady,
    query?.access_token,
    query?.refresh_token,
    isInitialized,
  ]);

  if (!authStore.accessToken && process.env.APP_ENV !== 'prod') {
    return <div id="missing">Need access token</div>;
  }

  return (
    <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>
  );
};
